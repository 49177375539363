// All global styling for Phlex pages should be included in this file.
import 'virtual:svg-icons-register' // TODO: Remove once we migrate all icons to V2Icon
import '@consumer/styles/global.scss'
import '@consumer/styles/giftly_image.scss'
import '@consumer/components/GButton.vue'
import '@consumer/modules/v2/layout/Footer.vue'
import ModalsManager from '@consumer/modules/shared/ModalsManager.vue'

// Page specific styles should be imported after components and before utilities.
import '@consumer/styles/consumer_gift_cards.scss'

// Utility styles should be imported after more specific styles.
import 'windi.css'

import { createApp } from 'vue'
createApp(ModalsManager).mount('#modals-manager')

// Note: We don't init sentry here, because there is no Vue app, if we want
// frontend sentry support, we'll need to do it the normal rails frontend way
export { initTracking } from '@shared/tracking'
export { setupHowItWorks } from '@consumer/global'
