import { openHowGiftlyWorksModal } from '@consumer/services/modals'

function onExactClick (selector: string, fn: (el: HTMLElement) => void) {
  document.querySelectorAll<HTMLElement>(selector).forEach((el) => {
    el.addEventListener('click', (event) => {
      if (event.metaKey || event.shiftKey || event.ctrlKey || event.altKey) return
      event.preventDefault()
      fn(el)
    })
  })
}

export function setupHowItWorks () {
  onExactClick('.how-it-works-modal-link', () => {
    openHowGiftlyWorksModal()
  })

  onExactClick('.how-it-works-demo-modal-link', () => {
    openHowGiftlyWorksModal({ initialStep: 'demo-step-1-notification' })
  })
}
